export interface CreationCostsJson {
  creation_costs_accepted?: boolean;
  creation_costs_user_id?: number;
  creation_costs_accepted_at?: Date;
}

export class CreationCosts {
  constructor(
    public creationCostsAccepted?: boolean,
    public creationCostsUserId?: number,
    public creationCostsAcceptedAt?: Date
  ) {}

  get needToShowCreationCosts(): boolean {
    return this.creationCostsAccepted === null || this.creationCostsAccepted === false;
  }

  static fromJson(json: CreationCostsJson): CreationCosts {
    return new CreationCosts(
      json.creation_costs_accepted,
      json.creation_costs_user_id,
      json.creation_costs_accepted_at
    );
  }

  static toJson(model: CreationCosts): CreationCostsJson {
    return {
      creation_costs_accepted: model.creationCostsAccepted,
      creation_costs_user_id: model.creationCostsUserId,
      creation_costs_accepted_at: model.creationCostsAcceptedAt
    };
  }
}
