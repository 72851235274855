import { createAction, props } from '@ngrx/store';

import { CompanyConfig } from '@models/company-config.model';

export const SELECT = '[CompanyConfig] Select';
export const UPDATE = '[CompanyConfig] Update';
export const REMOVE = '[CompanyConfig] Remove';

export const select = createAction(SELECT, props<{ companyConfig: CompanyConfig }>());
export const update = createAction(UPDATE, props<{ companyConfig: CompanyConfig }>());
export const remove = createAction(REMOVE);
