import { Capital, CapitalJson } from '@models/capital.model';
import { Company, CompanyJson } from '@models/company.model';

import { CreationCosts, CreationCostsJson } from './creation-costs.model';
import { MoralPerson, MoralPersonJson } from './moral-person.model';

export interface CreationJson {
  id: number;
  company: CompanyJson;
  capital: CapitalJson;
  creation_costs: CreationCostsJson;
  moral_persons: MoralPersonJson[];
}

export class Creation {
  constructor(
    public id: number,
    public company: Company,
    public capital: Capital,
    public creationCosts?: CreationCosts,
    public moralPersons?: MoralPerson[]
  ) {}

  static fromJson(json: CreationJson): Creation {
    return new Creation(
      json.id,
      json.company ? Company.fromJson(json.company) : null,
      json.capital ? Capital.fromJson(json.capital) : null,
      json.creation_costs ? CreationCosts.fromJson(json.creation_costs) : null,
      json.moral_persons
        ? json.moral_persons.map((moralPerson: MoralPersonJson) => MoralPerson.fromJson(moralPerson))
        : null
    );
  }
}
